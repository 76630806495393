@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

:root {
    --site_bg: #f5f5f5;
    --site_bgT: #f5f5f5bf;
    --site_bgP: #00aaa6;
    --site_bgPT: #00aaa6bf;
    --site_textM: #5c5c5c;
    --site_textL: #5c5c5cb3;
    --site_textD: #5c5c5c80;
    --site_primaryM: #00aaa6;
    --site_primaryT: #00aaa6bf;
    --site_textCP: #ffffff;
    --site_secondaryM: #f9a01b;
    --site_secondaryL: #4B4F5B;
    --site_secondaryD: #d16500;
    --site_secondaryT: #1f2332bf;
    --site_textCSe: #004241;}

* {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* border: 1px solid red; */
}

*:focus {
  outline: 0;
}
::selection {
  color: var(--site_textCP);
  background-color: var(--site_primaryT);
}

html,
body,
#root {
  scroll-behavior: smooth;
  font-size: 16px;
  height: 100%;
  width: 100%;
}

@media (max-width:1200px) {

  html,
  body,
  #root {
    font-size: 14px;
    height: 100%;
    width: 100%;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

li {
  list-style: none;
}

button {
  border-width: none;
  cursor: pointer;
}

/* SCROLLBAR*/
::-webkit-scrollbar {
  width: 0.3rem;
}

::-webkit-scrollbar-track {
  background: var(--site_secondaryM);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background: var(--site_primaryM);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--site_primaryM);
}